<template>
    <v-container
        class="px-0">
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-snackbar
                v-model="showPopup"
                :timeout="timeout"
                color="success"
                top
                center
                >
                {{ this.message }}
        </v-snackbar>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ComponentSubscriberActiveI/>
        <ComponentSubscriberInactive/>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../../components/common/ASuccessWithoutReload.vue';
import * as XLSX from 'xlsx';
import ComponentSubscriberActiveI from '../../../components/developer/report/ComponentSubscriberActiveI.vue';
import ComponentSubscriberInactive from '../../../components/developer/report/ComponentSubscriberInactive.vue';
// import ComponentTopSearchKeyword from '../../components/tracker/ComponentTopSearchKeyword.vue';
// import AFilterWithoutRefresh from '../../components/common/filter/AFilterWithoutRefresh.vue';
    export default {
    components:{
    ASuccessWithoutReload,
    ComponentSubscriberActiveI,
    ComponentSubscriberInactive 
    // ComponentTopSearchKeyword,
    // AFilterWithoutRefresh,
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        search:"",
        showPopup:false,
        message:"Text copied succesfully",
        timeout:2000,
        data:[],
        dataHeader:[
            {
                text:'User id',
                value:'user_id'
            },
            
            {
                text:'Tabs',
                value:'category'
            },
            {
                text:'Keyword',
                value:'keyword'
            },
            {
                text:'Has result',
                value:'is_found_content'
            },
            {
                text:'Click',
                value:'is_open_content',
            },
            {
                text:'Search date',
                value:'created_at'
            },
            
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class === "getSearchKeyword") {
                
                let tempData =  this.processSearchResult(this.$_.clone(resp.data));
                this.data = tempData;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
    },
    methods: {
        processSearchResult(data) {
            data.forEach(element => {
                if(element.created_at != null) {
                    element.created_at = this.$moment(element.created_at).format("YYYY-MM-DD HH:mm:ss");
                }
                element.is_found_content = element.is_found_content == true?"Found": "Empty";
                // element.is_open_content = element.is_open_content == true ? "Open" : "Not open";
            })
            return data;
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function

        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },
        processRenewalStatus(data) {
            data.forEach(element => {
                //Process the json
                let jsonData = JSON.parse(element.renewal);
                if (jsonData !== null) {
                    element.renewal_status = jsonData.renewal_status;
                    element.status = jsonData.status;
                    element.renewal_created_date = this.$moment(jsonData.created_at).format('YYYY-MM-DD HH:mm:ss');
                }
            });
            return data;
        },
        copyText(text) {
            navigator.clipboard.writeText(text);
            this.showPopup = true;
        },
        handleClosePopup() {
            this.showPopup = false;
        },
        downloadReport() {
            try{
                let downloadData = null;
                if(this.data.length > 0) {
                    downloadData = this.data.map(item=>{
                        return {
                            "User id":item.user_id,
                            "Tabs":item.category,
                            "Keyword":item.keyword,
                            "Has result":item.is_found_content,
                            "Content opened":item.is_open_content,
                            "created_at":item.created_at,
                        }
                    });
                }
                else {
                    downloadData = [
                        {
                            "User id":"",
                            "Tabs":"",
                            "Keyword":"",
                            "Has result":"",
                            "Content opened":"",
                            "created_at":"",
                        }
                    ]
                }
                let worksheet = XLSX.utils.json_to_sheet(downloadData);
                let workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook,worksheet,"Sheet1");
                const binaryData = XLSX.write(workbook,{bookType:'xlsx',type:'array'})
                const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download','report.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            }catch(error) {
                alert(error)
            }
        }
    }
}
</script>
<style>
.sticky-table {
    overflow-x:scroll;
}
.sticky-column {
    position:sticky;
    left:0;
    z-index:1;
    background:#f8f9fa;
    border-right: 1px solid #ddd;
}
td {
    min-width:200px;
    text-align:center;
    font-family: Arial, sans-serif;
    border-right: 1px solid #ddd;
}
th {
    text-align:center!important;
    background:#f8f9fa;
    border-top:1px solid #ddd;
    border-right: 1px solid #ddd;
}

.v-data-footer {
    background-color:#f8f9fa;
}
</style>