import * as XLSX from 'xlsx';
class excelUtils {
    static exportToExcel(data,fileName){
        try{
            let worksheet = XLSX.utils.json_to_sheet(data);
            let workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook,worksheet,"Sheet1");
            const binaryData = XLSX.write(workbook,{bookType:'xlsx',type:'array'})
            const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        }catch(error) {
            console.log(error);
        }
    }
}
export default excelUtils;